<!-- =========================================================================================
  Author: Nada Azzam
========================================================================================== -->

<template>
  <div id="data-list">
    <vs-popup
      classContent="popup-example"
      title="Confirm Delete"
      :active.sync="popupActive"
    >
      <validation-observer ref="observer" v-slot="{ invalid }">
        <form>
          <ValidationProvider
            name="password"
            rules="required|min:8"
            v-slot="{ errors }"
          >
            <vs-input
              :danger="!!errors[0]"
              :danger-text="errors[0]"
              type="password"
              name="password"
              icon-no-border
              icon="icon icon-lock"
              icon-pack="feather"
              label-placeholder="Password"
              v-model="password"
              class="w-full mt-6"
            />
          </ValidationProvider>

          <vs-button
            :disabled="invalid"
            type="filled"
            @click="goToDeleteCompany()"
            class="mb-2 mt-5"
            >Confirm</vs-button
          >
        </form>
      </validation-observer>
    </vs-popup>
    <div class="vx-card p-6">
      <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->

        <vs-button
          v-can:permission="'companies_create'"
          class="mb-4 md:mb-0"
          @click="goToAddCompany()"
        >
          <span style="font-weight: bold; font-size: 13px">+</span> Add Company
        </vs-button>
        <hr />
        <vs-button
          color="dark"
          v-can:permission="'companies_delete'"
          class="mb-4 md:mb-0"
          @click="openConfirm()"
        >
          <span style="font-weight: bold; font-size: 13px">-</span> Delete
          selected
        </vs-button>
      </div>
      <br />

      <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="
                p-4
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              "
            >
              <span class="mr-2">
                {{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                }}
                -
                {{
                  itemsData.length - currentPage * paginationPageSize > 0
                    ? currentPage * paginationPageSize
                    : itemsData.length
                }}
                From {{ itemsData.length }}
              </span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(40)">
                <span>40</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                <span>50</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <vs-input
          class="
            sm:mr-4
            mr-0
            sm:w-auto
            w-full
            sm:order-normal
            order-3
            sm:mt-0
            mt-4
          "
          v-model="searchQuery"
          @input="updateSearchQuery"
          placeholder="Search..."
        />
        <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()"
          >Export CSV</vs-button
        >
      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="itemsData"
        rowSelection="multiple"
        :animateRows="true"
        :floatingFilter="false"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
        @row-double-clicked="onRowDoubleClicked"
      ></ag-grid-vue>

      <vs-pagination :total="totalPages" :max="7" v-model="currentPage" />
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import vSelect from "vue-select";
import axios from "@/axios.js";

// Store Module
import moduleCompaniesManagement from "@/store/get-all-companies/moduleCompaniesManagement.js";

// Cell Renderer
import CellRendererLink from "../../hr-report/main-report/cell-renderer/CellRendererLink.vue";
import CellRendererStatus from "../../hr-report/main-report/cell-renderer/CellRendererStatus.vue";
// import btnCellRenderer from "../ui-elements/btn-cell-renderer.js";
// Router to navigate
import router from "@/router";
import endpoint from "@/endpoints";
import { required, min } from "vee-validate/dist/rules";
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");
// betterEager
extend("required", {
  ...required,
  message: "field can not be empty",
});

extend("min", {
  ...min,
  message: "field may not be less than {length} characters",
});
export default {
  components: {
    AgGridVue,
    vSelect,
    // Cell Renderer
    CellRendererLink,
    CellRendererStatus,
    // btnCellRenderer,
    // VALIDATION
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      page: 1,

      show: false,
      popupActive: false,
      password: null,
      companies: [],
      absenteeismDays: [{ name: 0 }, { name: 1 }, { name: "All" }],
      cities: [],
      areas: [],
      area_names: [],
      job_titles: [],
      city: null,
      category: null,
      items: {
        start_date: null,
        end_date: null,
        category: null,
        company: null,
        job_title: null,
        employee_name: null,
        employee_code: null,
        reporting_to: null,
        city: null,
        area: null,
        area_name: null,
        Absenteeism_Days: null,
      },
      configFromdateTimePicker: {
        allowInput: true,
        minDate: null,
        maxDate: new Date(),
      },
      configTodateTimePicker: {
        minDate: null,
        maxDate: new Date(),
      },

      searchQuery: "",

      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        resizable: true,
      },
      columnDefs: [
        {
          headerName: "Code",
          field: "code",
          sortable: true,
          filter: true,
          checkboxSelection: true,
          width: 300,
        },
        {
          headerName: "NAME",
          field: "name",
          sortable: true,
          filter: true,
          width: 800,
        },
      ],

      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererStatus,
      },
    };
  },

  computed: {
    itemsData() {
      // ////////console.log("hahahahahah : " + this.$store.state.company.items);
      return this.$store.state.company.items;
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    openConfirm() {
      this.popupActive = true;
    },
    goToAddCompany() {
      router.push("/Add/Company");
    },
    goToDeleteCompany() {
      this.$vs.loading();
      const selectedNodes = this.gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => node.data);
      const selectedDataStringPresentation = selectedData.map(
        (node) => node.id
      );
      let obj = {
        ids: selectedDataStringPresentation,
        password: this.password,
      };
      axios
        .delete(`${endpoint.COMPANIES_ENDPOINT}/delete`, { data: obj })
        .then((response) => {
          this.$vs.loading.close();
          if (response.status === 200) {
            this.onRemoveSelected();
            this.$vs.notify({
              title: "success",
              text: response.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
            this.popupActive = false;
          } else {
            this.$vs.notify({
              title: "Error",
              text: response.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }
          //      document.location.reload();
        })
        .catch(() => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: "Can't delete this area",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },

    onRemoveSelected() {
      var selectedData = this.gridApi.getSelectedRows();
      //alert(JSON.stringify(selectedData));
      this.gridApi.applyTransaction({ remove: selectedData });

      //  printResult(res);
    },
    onRowDoubleClicked(event) {
      let permissions = this.$store.state.auth.permissions.length
        ? this.$store.state.auth.permissions
        : localStorage.getItem("permissions");
      if (permissions && permissions.indexOf("companies_edit") === -1) {
        return;
      }
      router.push("/Update/Company?SID=" + event.data.id);
    },
    onFromChange(selectedDates, dateStr) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    setColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column);
      let modelObj = null;

      if (val !== "all") {
        modelObj = { type: "equals", filter: val };
      }

      filter.setModel(modelObj);
      this.gridApi.onFilterChanged();
    },
    resetColFilters(card) {
      card.removeRefreshAnimation(500);
      this.items.start_date =
        this.items.end_date =
        this.items.category =
        this.category =
        this.items.company =
        this.items.job_title =
        this.items.employee_name =
        this.items.employee_code =
        this.items.reporting_to =
        this.items.city =
        this.city =
        this.items.area =
        this.items.area_name =
        this.items.Absenteeism_Days =
          null;

      // Reset Grid Filter
      // this.gridApi.setFilterModel(null);
      // this.gridApi.onFilterChanged();
      // this.$refs.filterCard.removeRefreshAnimation();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
  },

  mounted() {
    this.gridApi = this.gridOptions.api;

    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        ".ag-header-container"
      );
      header.style.left =
        "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
    }

    this.$store.dispatch("company/fetchItemsCompanies").then((response) => {
      this.$vs.loading.close();
      if (response.data.status === 404) {
        this.$vs.notify({
          title: "Error",
          text: "Make sure select the main filters ",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      }
    });
  },
  created() {
    if (!moduleCompaniesManagement.isRegistered) {
      this.$store.registerModule("company", moduleCompaniesManagement);
      moduleCompaniesManagement.isRegistered = true;
    }
  },
};
</script>
